import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import SpecialtiesDirectory from '../../components/Specialties/components/SpecialtiesDirectory';

export default () => (
  <>
    <SEO
      title="Specialties | Mindset Family Therapy"
      description="Mindset Family Therapy specialties."
      pathname="/specialties"
    />
    <Layout>
      <SpecialtiesDirectory />
    </Layout>
  </>
);
