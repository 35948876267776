import _ from 'lodash';
import React from 'react';
import { Link } from 'gatsby';
import PageHeader from '../../../PageHeader';
import data from '../../../../data/siteDirectory';

import styles from './specialtiesDirectory.module.scss';

const SpecialtiesDirectory = () => {
  const background =
    'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1497108735/therapistFullBackgroung_lapqmb.jpg';
  const specialties = _.map(data.specialties, (specialty, index) => {
    return (
      <li key={index}>
        <span>
          <Link to={specialty.route}>{specialty.name}</Link>
        </span>
      </li>
    );
  });

  return (
    <main>
      <PageHeader pageName="Specialties" headerImage={background} />
      <div className={styles.textContainer}>
        <h2 className={styles.header}>Our Specialties</h2>
        <ul>{specialties}</ul>
      </div>
    </main>
  );
};

export default SpecialtiesDirectory;
